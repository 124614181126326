<template>
    <div>
        <b-sidebar backdrop aria-labelledby="sidebar-no-header-title" no-header id="add_outreceipt" width="50rem" style="direction:ltr" right title="سند صرف" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:150px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.exchange_voucher}}</span>
        </div>
        <div @click="hide" id="ohidid" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
            <v-form ref="addform">
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="4" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                :label="$parent.lang.date"
                                prepend-icon="mdi-calendar"
                                :rules="fieldRules"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="mobile"
                            type="number"
                            :label="$parent.lang.mobile"
                            :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="full_name"
                            :label="$parent.lang.full_name"
                            :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="received_total"
                            :label="$parent.lang.total_amount"
                            :rules="fieldRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">

                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="selected"
                            :items="resons"
                            :label="$parent.lang.description"
                            :rules="fieldRules"
                        ></v-select>
                        
                    </v-col>
                    <v-col cols="12" md="4" sm="12">

                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="paytype"
                            :items="paytypes"
                            :label="$parent.lang.paytypes"
                            :rules="fieldRules"
                        ></v-select>
                        
                    </v-col>
                    <!-- <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="vselected"
                            :items="vatopt"
                            label="سبب الصرف"
                            :rules="fieldRules"
                        ></v-select>
                    </v-col> -->
                    <v-col cols="12" md="6" sm="12">
                        <v-textarea
                            v-model="notes"
                            label="البيان"
                            :rules="fieldRules"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </div>
        </v-form>
        </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click="addVoucher()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            mobile: '',
            full_name:'',
            received_total:'',
            notes:'',
            selected:null,
            vselected:null,
            paytype: null,
            paytypes: [],
            options:[],
            voptions:[],
            voutcher_reson:'',
            resons:[
            ],
            vatopt:[
                {
                    value: 1,
                    text: 'لا تحتوي على ضريبة'
                },
                {
                    value: 2,
                    text: 'المبلغ شامل الضريبة'
                },
                {
                    value: 3,
                    text: 'المبلغ غير شامل الضريبة'
                },
            ],

        }
    },
    created(){
        this.getTypes();
    },
    methods: {
        getTypes(){
            const post = new FormData();
            post.append("type",'getExpenses');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[type]',2)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    this.resons = response.data.results.data;
                    this.paytypes = response.data.results.paytypes;
                }
            );
        },
        resetAll(){
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.modal = false;
            this.mobile = '';
            this.full_name ='';
            this.received_total ='';
            this.notes ='';
            this.paytype = null;
            this.selected =null;
            this.vselected =null;
            this.options =[];
            this.voptions =[];
            this.voutcher_reson ='';
        },
        checkData(){
            // if(typeof document.getElementById('add_outreceipt') !== 'undefined'
            //     || !!document.getElementById('add_outreceipt') != true
            // )
            // {
            //     if(document.getElementById('add_outreceipt').style.display != 'none'){
            //         if(this.received_total == 0){
            //             //this.received_total = this.$parent.cardrows[this.$parent.activeClick].remaining;
            //         }
            //     }else{
            //         this.received_total = 0;
            //     }
            // }
        },
        addVoucher(){
            if(!this.$refs.addform.validate()){

                return false;
            }
            const post = new FormData();
            post.append("type",'addOVouchter');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[type]',2)
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[amount]',this.received_total)
            post.append('data[notes]',this.notes)
            post.append('data[date]',this.date)
            post.append('data[selected]',this.selected)
            post.append('data[vselected]',this.vselected);
            post.append('data[paytype]',this.paytype);
            // post.append('data[dailydate]',this.$parent.cardrows[this.$parent.activeClick].edate ?? 0);
            // post.append('data[dailyid]',this.$parent.cardrows[this.$parent.activeClick].id ?? 0);
                       
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.modal = false;
                    this.mobile = '';
                    this.full_name ='';
                    this.received_total ='';
                    this.notes = '';
                    this.$snotify.create({
                    title: ' ',
                        body: this.$parent.lang.added_successfuly,
                        config: {
                            position: SnotifyPosition.leftTop,
                            type: SnotifyStyle.success,
                            timeout: 5000,
                            showProgressBar: true,
                            closeOnClick: true,
                        }
                    })
                    document.getElementById('ohidid').click();
                    this.$parent.getVouchers();
                }
            )
        }
    },
}
</script>
<style>
.v-input__icon--append i{
    font-size:3em !important;  
    color:red !important;
}
</style>